export const environment = {
  production: true,
  DOMAIN: ".easykash.net",
  BASE_URL: 'https://dev.easykash.net/',
  API_URL: 'https://dev.easykash.net/api/',
  OtlobxUrl:"https://otlobx.com/api/",
  BILLER_URL: 'https://dev.easykash.net/devbill/api/bill/',
  GATE_URL: 'https://dev.easykash.net/gate/',
  UPG_LIGHTBOX: 'https://upg.egyptianbanks.com:2008/INVCHOST/js/Lightbox.js',
  PaySky_LIGHTBOX: 'https://grey.paysky.io:9006/invchost/JS/LightBox.js',
  delay: 5000,
  NEW_GATE_LIGHTBOX: 'https://dev.easykash.net/gate/lightBox/sdk/v2',
  SECURE_KEY: 'LFi3DiVB6obLigppY5ud83U3JoHsnKfHv4kc93F3lSxY',
  RECAPTCHA_KEY: '6LfiTuIeAAAAAPpUOBAx53W4OdmtjEj_HahAiYGU',
  billerClientId : 'PK_adly0z'
};
